import { docuSignApi } from 'store/api';
import { IEmbeddedSigningData } from 'api/digifi/DocuSignApi';
import { apiSlice } from './apiSlice';

export const docuSignSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDocuSignIntegrationData: builder.query<
      IEmbeddedSigningData | null,
      string
    >({
      queryFn: async (applicationDisplayId: string) => {
        try {
          const { data } = await docuSignApi.getEmbeddedSigningData(applicationDisplayId);

          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetDocuSignIntegrationDataQuery } = docuSignSlice;
