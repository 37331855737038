import React from 'react';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import Onboarding from 'components/Auth/Onboarding';
import styles from './OnboardingPage.module.scss';

const OnboardingPage = () => {
  return (
    <UnauthorizedLayout innerContentClassName={styles.innerContent}>
      <Onboarding />
    </UnauthorizedLayout>
  );
};

export default OnboardingPage;
