import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { useAppSelector } from 'hooks/reduxHooks';
import { ApplicationFormPageBehavior, ApplicationFormPageType, IProduct } from 'api/digifi/ProductsApi';
import OnboardingStep from 'enums/OnboardingStep';
import useOnboardingStepsNavigation from 'hooks/useOnboardingStepsNavigation';
import { VariableValue } from 'product_modules/api/Types';
import useSessionStorage from 'hooks/useSessionStorage';

const COBORROWER_FORM_PAGE_TYPES = [
  ApplicationFormPageType.CoBorrower,
  ApplicationFormPageType.CoBorrower2,
  ApplicationFormPageType.CoBorrower3,
];

const COBORROWER_INFORMATION_STEPS = [
  OnboardingStep.CoBorrower,
  OnboardingStep.CoBorrower2,
  OnboardingStep.CoBorrower3,
];

export interface IOnboardingData {
  borrowerType: BorrowerType | null;
  product: IProduct | null;
  borrowerFormData: Record<string, VariableValue>;
  coBorrowerTypes: Array<BorrowerType>;
  coBorrowerFormData: Array<Record<string, VariableValue>>;
}

const INITIAL_ONBOARDING_DATA: IOnboardingData = {
  borrowerType: null,
  product: null,
  borrowerFormData: {},
  coBorrowerTypes: [],
  coBorrowerFormData: [],
};

const ONBOARDING_DATA_STORAGE_KEY = 'onboardingData';

const useOnboardingFlow = () => {
  const { onChangeStep, clearSteps, ...stepsNavigation } = useOnboardingStepsNavigation();
  const sessionStorage = useSessionStorage({ encode: true });
  const { borrowerTypes } = useAppSelector((state) => state.settings);
  const { ids: productIds, entities: productEntities } = useAppSelector((state) => state.products);

  const sessionStorageData = useMemo(() => {
    return sessionStorage.get(ONBOARDING_DATA_STORAGE_KEY, true);
  }, []);

  const [onboardingData, setOnboardingData] = useState<IOnboardingData>(sessionStorageData || INITIAL_ONBOARDING_DATA);

  const clearOnboardingData = useCallback(() => {
    sessionStorage.remove(ONBOARDING_DATA_STORAGE_KEY);
    clearSteps();
  }, [clearSteps]);

  useEffect(() => {
    if (borrowerTypes.length > 1) {
      return;
    }

    const borrowerType = borrowerTypes[0];

    setOnboardingData((previousState) => ({ ...previousState, borrowerType }));

    onChangeStep(OnboardingStep.BorrowerType);
  }, []);

  useEffect(() => {
    const { borrowerType } = onboardingData;

    if (!borrowerType) {
      return;
    }

    const filteredProducts = productIds
      .map((id) => productEntities[id])
      .filter((product) => product?.borrowerTypes.includes(borrowerType));

    if (filteredProducts.length > 1) {
      onChangeStep(OnboardingStep.Product, false);

      return;
    }

    const [product] = filteredProducts;

    if (!product) {
      return;
    }

    setOnboardingData((previousValue) => ({ ...previousValue, product, borrowerFormData: {} }));

    onChangeStep(OnboardingStep.Product);
  }, [onboardingData?.borrowerType, productEntities, productIds]);

  useEffect(() => {
    const { product } = onboardingData;

    if (!product || isEqual(sessionStorageData, onboardingData)) {
      return;
    }

    const shouldDisplayCoborrowersStep = COBORROWER_FORM_PAGE_TYPES.some((key) => product.applicationFormPages?.[key] === ApplicationFormPageBehavior.Optional);

    setOnboardingData((previousValue) => ({
      ...previousValue,
      coBorrowerTypes: COBORROWER_FORM_PAGE_TYPES.reduce((result, key) => {
        if (product.applicationFormPages?.[key] === ApplicationFormPageBehavior.Required) {
          result.push(product.borrowerTypes[0]);
        }

        return result;
      }, [] as Array<BorrowerType>),
    }));

    onChangeStep(OnboardingStep.Coborrowers, !shouldDisplayCoborrowersStep);
  }, [onboardingData.product]);

  useEffect(() => {
    COBORROWER_INFORMATION_STEPS.forEach((step ,index) => {
      onChangeStep(step, !onboardingData.coBorrowerTypes[index]);
    });
  }, [onboardingData.coBorrowerTypes]);

  useEffect(() => {
    sessionStorage.set(ONBOARDING_DATA_STORAGE_KEY, onboardingData);
  }, [onboardingData]);

  return [
    onboardingData,
    setOnboardingData,
    clearOnboardingData,
    stepsNavigation,
  ] as [
    typeof onboardingData,
    typeof setOnboardingData,
    typeof clearOnboardingData,
    typeof stepsNavigation,
  ];
};

export default useOnboardingFlow;
