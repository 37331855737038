import { useEffect, useCallback, useState, FC } from 'react';
import clsx from 'clsx';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import { useGetDocuSignIntegrationDataQuery } from 'slices/docuSignSlice';
import NoDocumentsToSign from './NoDocumentsToSign';
import WaitingForOthers from './WaitingForOthers';
import FailedToLoadDocument from './FailedToLoadDocument';
import useDocuSignWidget from './useDocuSignWidget';
import SessionTimeout from './SessionTimeout';
import styles from './ESignatureElement.module.scss';

interface IESignatureElementProps {
  applicationDisplayId: string;
}

const MOUNT_CONTAINER_ID = 'agreementContainer';

const ESignatureElement: FC<IESignatureElementProps> = ({ applicationDisplayId }) => {
  const [isError, setIsError] = useState(false);
  const [isSessionTimeout, setIsSessionTimeout] = useState(false);
  const [errorSigningUrl, setErrorSigningUrl] = useState<string | null>(null);

  const {
    currentData: docuSignIntegrationData,
    isLoading: isDocuSignIntegrationDataLoading,
    refetch: handleDocuSignIntegrationDataRefetch,
    isFetching: isDocuSignIntegrationDataFetching,
    error: getDocuSignIntegrationDataError,
  } = useGetDocuSignIntegrationDataQuery(applicationDisplayId);

  const isLoading = isDocuSignIntegrationDataLoading || isDocuSignIntegrationDataFetching;
  const showWidget = !isLoading && docuSignIntegrationData?.embeddedSigningUrl && docuSignIntegrationData?.clientId;
  const showNoDocumentsToSign = docuSignIntegrationData === null
    || (docuSignIntegrationData && !docuSignIntegrationData.embeddedSigningUrl);
  const showWaitingForOthers = docuSignIntegrationData && docuSignIntegrationData.waitingForOthers;

  const handleError = useCallback(() => {
    setIsError(true);
    setErrorSigningUrl(docuSignIntegrationData?.embeddedSigningUrl || null);
  }, [docuSignIntegrationData?.embeddedSigningUrl]);

  const handleSessionEnd = useCallback((event) => {
    switch (event.sessionEndType) {
      case 'signing_complete':
      case 'decline':
      case 'viewing_complete':
        return handleDocuSignIntegrationDataRefetch();
      case 'session_timeout':
        return setIsSessionTimeout(true);
      default:
        return handleError();
    }
  }, [docuSignIntegrationData]);

  const handleRetry = useCallback(() => {
    if (errorSigningUrl) {
      window.open(errorSigningUrl, '_blank');
    }

    setIsError(false);
    setIsSessionTimeout(false);
    handleDocuSignIntegrationDataRefetch();
  }, [handleDocuSignIntegrationDataRefetch, errorSigningUrl]);

  const loadDocuSignWidget = useDocuSignWidget({
    bundleUrl: docuSignIntegrationData?.bundleUrl || '',
    onSessionEnd: handleSessionEnd,
    onError: handleError,
    mountContainerId: MOUNT_CONTAINER_ID,
  });

  useEffect(() => {
    if (docuSignIntegrationData?.embeddedSigningUrl && docuSignIntegrationData?.clientId) {
      loadDocuSignWidget(docuSignIntegrationData.clientId, docuSignIntegrationData.embeddedSigningUrl);
    }
  }, [loadDocuSignWidget, docuSignIntegrationData]);

  useEffect(() => {
    if (getDocuSignIntegrationDataError) {
      handleError();
    }
  }, [getDocuSignIntegrationDataError, handleError]);

  return (
    <>
      {isLoading && (
        <div className={styles.loaderContainer}>
          <LoaderOverlay loaderClassName={styles.loader} />
        </div>
      )}
      {showWaitingForOthers && <WaitingForOthers />}
      {!showWaitingForOthers && showNoDocumentsToSign && <NoDocumentsToSign />}
      {isError && <FailedToLoadDocument onClick={handleRetry} />}
      {isSessionTimeout && <SessionTimeout onClick={handleRetry} />}
      <div
        id={MOUNT_CONTAINER_ID}
        className={clsx(styles.embeddedSigningContainer, showWidget && styles.showEmbeddedSigningContainer)}
      />
    </>
  );
};

export default ESignatureElement;
