import { FC } from 'react';
import Button from 'components/digifi-wrappers/Button';
import { ElementAlignment, IButtonElementConfig } from 'api/digifi/portal-page-elements';
import styles from './ButtonElement.module.scss';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import clsx from 'clsx';

const DEFAULT_BUTTON_TEXT = 'Button';

interface IButtonElementProps {
  config: IButtonElementConfig;
  onSubmit: () => Promise<void>;
  containerClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  tooltip?: React.ReactNode;
}

const mapAlignmentToJustify = (value: ElementAlignment) => {
  switch (value) {
    case ElementAlignment.Center:
      return 'center';
    case ElementAlignment.Left:
      return 'flex-start';
    case ElementAlignment.Right:
      return 'flex-end';
  }
};

const ButtonElement: FC<IButtonElementProps> = ({
  config,
  onSubmit,
  containerClassName,
  disabled,
  isLoading,
  tooltip,
}) => {
  return (
    <div
      className={clsx(styles.container, containerClassName)}
      style={{ justifyContent: mapAlignmentToJustify(config.alignment) }}
    >
      <WrapperWithTooltip tooltip={tooltip}>
        <span className={styles.buttonWrapper}>
          <Button
            size="form"
            kind="primary"
            disabled={disabled}
            onClick={onSubmit}
            isLoading={isLoading}
            className={styles.button}
          >
            {config.text || DEFAULT_BUTTON_TEXT}
          </Button>
        </span>
      </WrapperWithTooltip>
    </div>
  );
};

export default ButtonElement;
