interface ISessionStorageOptions {
  encode?: boolean;
}

const useSessionStorage = (options?: ISessionStorageOptions) => {
  return {
    set: (key: string, value: unknown) => {
      const item = typeof value === 'string' ? value : JSON.stringify(value);

      window.sessionStorage.setItem(key, options?.encode ? btoa(item) : item);
    },
    get: (key: string, useParse?: boolean) => {
      const item = window.sessionStorage.getItem(key);

      if (!item) {
        return null;
      }

      const decodedItem = options?.encode ? atob(item) : item;

      return useParse ? JSON.parse(decodedItem) : decodedItem;
    },
    remove: (key: string) => {
      window.sessionStorage.removeItem(key);
    },
  };
};

export default useSessionStorage;
