import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'enums/AppRoute';
import useSessionStorage from 'hooks/useSessionStorage';

interface IStep<PossibleSteps extends Record<string, string>> {
  step: StepValue<PossibleSteps>;
  isSkipped: boolean;
}

export type StepValue<PossibleSteps extends Record<string, string>> = PossibleSteps[keyof PossibleSteps];

const INITIAL_CURRENT_STEP_INDEX = 0;

const useStepsNavigation = <Steps extends Record<string, string>>(
  navigationSteps: Steps,
  stepsDataStorageKey: string,
  currentStepIndexStorageKey: string,
) => {
  const navigate = useNavigate();
  const sessionStorage = useSessionStorage({ encode: true });

  const [steps, setSteps] = useState<IStep<Steps>[]>(
    () => Object.values(navigationSteps).map((step) => ({ step, isSkipped: false })) as IStep<Steps>[],
  );
  const [currentStepIndex, setCurrentStepIndex] = useState(INITIAL_CURRENT_STEP_INDEX);

  useEffect(() => {
    const sessionStorageSteps = sessionStorage.get(stepsDataStorageKey, true);
    const sessionStorageCurrentStepIndex = sessionStorage.get(currentStepIndexStorageKey);

    if (sessionStorageSteps) {
      setSteps(sessionStorageSteps);
    }

    if (sessionStorageCurrentStepIndex) {
      setCurrentStepIndex(Number(sessionStorageCurrentStepIndex));
    }
  }, []);

  const onStepBack = useCallback(() => {
    if (currentStepIndex === 0) {
      navigate(AppRoute.Home);
      return;
    }

    const validSteps = steps.filter((step) => !step.isSkipped);

    const currentValidIndex = validSteps.findIndex((step) => {
      return steps.indexOf(step) === currentStepIndex;
    });

    if (currentValidIndex <= 0) {
      navigate(AppRoute.Home);
      return;
    }

    setCurrentStepIndex(steps.indexOf(validSteps[currentValidIndex - 1]));
  }, [currentStepIndex, steps]);

  const onStepForward = useCallback(() => {
    const newStep = steps.slice(currentStepIndex + 1).filter((step) => !step.isSkipped)[0];

    const index = steps.findIndex((step) => step.step === newStep.step);

    setCurrentStepIndex(index);
  }, [currentStepIndex, steps]);

  const onChangeStep = useCallback(
    (step: StepValue<Steps>, isSkipped = true) => {
      setSteps((prevState) => prevState.map((item) => (item.step === step ? { ...item, isSkipped } : item)));
    },
    [currentStepIndex, sessionStorage],
  );

  const clearSteps = useCallback(() => {
    sessionStorage.remove(stepsDataStorageKey);
    sessionStorage.remove(currentStepIndexStorageKey);
  }, []);

  useEffect(() => {
    if (steps[currentStepIndex].isSkipped) {
      onStepForward();
    }
  }, [steps, currentStepIndex]);

  useEffect(() => {
    sessionStorage.set(stepsDataStorageKey, steps);
  }, [steps]);

  useEffect(() => {
    sessionStorage.set(currentStepIndexStorageKey, currentStepIndex);
  }, [currentStepIndex]);

  const currentStep = steps[currentStepIndex].step;

  return {
    currentStep,
    onStepForward,
    onStepBack,
    onChangeStep,
    clearSteps,
  } as {
    currentStep: typeof currentStep;
    onStepForward: typeof onStepForward;
    onStepBack: typeof onStepBack;
    onChangeStep: typeof onChangeStep;
    clearSteps: typeof clearSteps;
  };
};

export default useStepsNavigation;
