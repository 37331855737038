import React, { FC, useEffect, useState } from 'react';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import VerificationForm, { IVerificationFormContextType } from 'components/Auth/forms/VerificationForm/VerificationForm';
import { getCurrentAccount, sendEmailVerificationCode, verifyEmail } from 'handlers/authSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { Formik } from 'formik';
import { createNotification } from 'handlers/notificationsSlice';
import { AppRoute } from 'enums/AppRoute';
import { useNavigate } from 'react-router-dom';
import { RequestError } from 'errors/RequestError';
import { HttpStatusCode } from 'enums/HttpStatusCode';
import FormTitle from 'components/PageLayout/FormTitle';
import AuthFormContainer from 'components/Auth/AuthFormContainer';

const EmailVerification: FC = () => {
  const { accountData } = useAppSelector((state) => state.auth);
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(sendEmailVerificationCode());
  }, []);

  const sendCode = () => {
    dispatch(sendEmailVerificationCode());
  };

  const handleSubmit = async (values: IVerificationFormContextType) => {
   try {
     setIsLoading(true);
     await dispatchWithUnwrap(verifyEmail(values.code));
     await dispatchWithUnwrap(getCurrentAccount());
     setIsLoading(false);
   } catch (error) {

     const requestError = (error as RequestError);
     setIsLoading(false);

     if (requestError.responseStatus === HttpStatusCode.BadRequest) {
       navigate(AppRoute.SignIn);
     }

     createNotification({ notification: requestError.message, type: 'error', dispatch });
   }
  };

  return (
    <UnauthorizedLayout
      footerLinkText="Back To Sign In"
      footerLink={AppRoute.SignIn}
      logoutOnLinkClick
    >
      <AuthFormContainer>
        <FormTitle title="Email Verification" subTitle={`Please enter the code sent to ${accountData?.email}`} />
        <Formik initialValues={{ code: '' }} onSubmit={handleSubmit}>
          <VerificationForm buttonTitle="Verify Email" resendCode={sendCode} isLoading={isLoading} />
        </Formik>
      </AuthFormContainer>
    </UnauthorizedLayout>
  );
};

export default EmailVerification;
