import React, { FC, useState } from 'react';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import SignInForm from 'components/Auth/forms/SignInForm';
import { ISignInFormContextType } from 'components/Auth/forms/SignInForm/SignInForm';
import { AppRoute } from 'enums/AppRoute';
import {
  getCurrentAccount,
  signIn,
} from 'handlers/authSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppDispatch } from 'hooks/reduxHooks';
import { createNotification } from 'handlers/notificationsSlice';
import FormFooterLink from 'components/PageLayout/FormFooterLink';
import AuthFormContainer from 'components/Auth/AuthFormContainer';
import FormTitle from 'components/PageLayout/FormTitle';

const SignIn: FC = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (values: ISignInFormContextType) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(signIn(values));
      await dispatchWithUnwrap(getCurrentAccount());
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      createNotification({ notification: error.message, type: 'error', dispatch });
    }
  };

  return (
      <UnauthorizedLayout
        hideSignInButton
      >
        <AuthFormContainer>
          <FormTitle title="Welcome Back" subTitle="Please sign in to your account." />
          <SignInForm
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
          <FormFooterLink
            text="New User?"
            linkText="Apply Now"
            to={AppRoute.Home}
          />
        </AuthFormContainer>
      </UnauthorizedLayout>
  );
};

export default SignIn;
